import Sumit_anand from '../../Images/Team/Sumit_anand.jpg';
import Adrita_dutta from '../../Images/Team/Adrita_dutta.jpg';
import Amit_das from '../../Images/Team/Amit_das.jpg';
import Anish from '../../Images/Team/Anish.jpg';
import Anjali_haritwal from '../../Images/Team/Anjali_haritwal.jpg';
import Aritra from '../../Images/Team/Aritra.jpg';
import Arkaprabha_munsi from '../../Images/Team/Arkaprabha_munsi.jpg';
import Arnab from '../../Images/Team/Arnab.jpg';
import Aryaman_singh from '../../Images/Team/Aryaman_singh.jpg';
import Ashish_raj from '../../Images/Team/Ashish_raj.jpg';
import Astha_mishra from '../../Images/Team/Astha_mishra.jpg';
import Dipak from '../../Images/Team/Dipak.jpg';
import Faisal_khan from '../../Images/Team/Faisal_khan.jpg';
import Harsh from '../../Images/Team/Harsh.jpg';
import Khushi_Bhaiya from '../../Images/Team/Khushi_Bhaiya.jpg';
import Mayukh_saha from '../../Images/Team/Mayukh_saha.jpg';
import Pritam from '../../Images/Team/Pritam.jpg';
import Ranit_bhoumik from '../../Images/Team/Ranit_bhoumik.jpg';
import Saheli from '../../Images/Team/Saheli.jpg';
import Sakshi_arya from '../../Images/Team/Sakshi_arya.jpg';
import Shagnik_mukherjee from '../../Images/Team/Shagnik_mukherjee.jpg';
import Shreya_banerjee from '../../Images/Team/Shreya_banerjee.jpg';
import Snehamoy_barua from '../../Images/Team/Snehamoy_barua.jpg';
import Somnath_bhaumik from '../../Images/Team/Somnath_bhaumik.jpg';
import Sukanya_sen from '../../Images/Team/Sukanya_sen.jpg';
import Upasana_roy from '../../Images/Team/Upasana_roy.jpg';
import Varun from '../../Images/Team/Varun.jpg'; 

export const TeamData = [
    Sumit_anand, Adrita_dutta, Anjali_haritwal, Astha_mishra, Amit_das, Anish, Aritra, Arkaprabha_munsi, Arnab, Aryaman_singh, Ashish_raj, Dipak, Faisal_khan, Harsh, Khushi_Bhaiya, Mayukh_saha, Pritam, Ranit_bhoumik, Saheli, Sakshi_arya, Shagnik_mukherjee, Shreya_banerjee, Snehamoy_barua, Somnath_bhaumik, Sukanya_sen, Upasana_roy, Varun
]